import Header from './Header';
import Footer from './Footer';

const PageWrapper = ({ children, wrapperClassName, wrapperStyles }) => {
	return (
		<div
			className={`flex flex-col overflow-x-hidden !bg-transparent ${wrapperClassName}`}
			style={wrapperStyles}
		>
			<Header />
			<div className='!pt-[75px] sm:pt-0 bg-gree-300'>{children}</div>

			<div>
				<Footer />
			</div>
		</div>
	);
};

export default PageWrapper;
