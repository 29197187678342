import { Col, Container, Image, Row } from 'react-bootstrap';
import { Link as ScrollTo } from 'react-scroll';
import Buttons from '../../../Litho/Components/Button/Buttons';
import colors from '../../../constants/colors';

const LandingSection = () => {
	return (
		<div className='overflow-hidden relative'>
			<Container className='relative'>
				<Row className='items-center justify-between pt-0 sm:!pt-16 h-[calc(100vh-75px)] md:h-[calc(650px-80px)] sm:h-[calc(550px-80px)]'>
					<Col lg={6} md={6} sm={8}>
						<span className='text-xmd font-serif font-medium uppercase text-tprimary block mb-[35px] xs:mb-[15px]'>
							Exquisite Mastery
						</span>
						<h3 className='font-roboto font-bold text-tprimary !text-3xl uppercase mb-[2.5rem] sm:mb-[20px] xs:mb-[15px]'>
							Elevate your space with lasting quality.
						</h3>
						<p className='text-xmd text-ttertiary leading-[30px] w-[85%] mb-12 lg:w-[90%] md:w-full xs:mb-[30px]'>
							Crafting spaces that feel like home. Discover timeless furniture
							pieces designed to elevate comfort, style, and quality in every
							corner of your space.
						</p>

						<Buttons
							ariaLabel='tab button'
							className='btn-flat rounded-full font-medium font-serif text-tprimary hover:!text-white tracking-[1px] uppercase md:mb-[15px] xs:mb-0 xs:mt-[20px]'
							size='lg'
							themeColor={colors.tprimary}
							to='/about'
							title='Get started now'
						/>
					</Col>

					<Col lg={6} md={6} sm={4} className='flex justify-end'>
						<Image
							src='/assets/img/home-page/landing-dinning.webp'
							alt='gold'
							className='object-cover h-full sm:hidden flex'
							width='470px'
						/>
					</Col>
				</Row>
				<ScrollTo
					to='activities'
					offset={100}
					delay={0}
					spy={true}
					smooth={true}
					duration={800}
					className='w-full absolute bottom-[30px] left-0 right-0 z-[1] flex justify-center items-center xs:hidden cursor-pointer'
				>
					<i className='fas fa-arrow-down text-lg text-tsecondary bg-tprimary shadow-[0_0_25px_rgba(0,0,0,0.08)] w-[45px] h-[45px] leading-[46px] rounded-full flex justify-center items-center'></i>
				</ScrollTo>
			</Container>
		</div>
	);
};

export default LandingSection;
