import {useRef} from 'react';
import {Col, Container, Image, Row} from 'react-bootstrap';
import {Formik, Form} from 'formik';
import {Input, TextArea} from '../../../Litho/Components/Form/Form';
import Buttons from '../../../Litho/Components/Button/Buttons';
import {AnimatePresence} from 'framer-motion';
import {ContactFormStyle02Schema} from '../../../Litho/Components/Form/FormSchema';
import {resetForm, sendEmail} from '../../../Litho/Functions/Utilities';
import MessageBox from '../../../Litho/Components/MessageBox/MessageBox';
import colors from "../../../constants/colors";

const ContactForm = () => {
  const form = useRef(null);

  return (
    <section
      className='py-[160px] lg:py-[120px] md:py-[95px] xs:py-[80px]'
      style={{
        backgroundImage: 'url(assets/img/home-page/dots-pattern.png)',
      }}
    >

      <Container>
        <Row className='items-center justify-center'>
          <Col className='col-12 col-lg-5 col-md-8 md:mb-[50px]'>
            <Image
              src='/assets/img/contact-us-page/wood.png'
              alt='gold-contact'
            />
          </Col>
          <Col className='col-12 col-lg-6 offset-lg-1 col-md-8'>
            <h4 className='font-serif text-tprimary font-semibold'>
              Let's get in touch with us
            </h4>
            <Row className='justify-center'>
              <Col>
                <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    comment: '',
                  }}
                  validationSchema={ContactFormStyle02Schema}
                  onSubmit={async (values, actions) => {
                    console.log('button clicked');
                    actions.setSubmitting(true);
                    console.log('button clicked1');
                    const response = await sendEmail(values);
                    console.log('button clicked2');
                    response.status === 'success' && resetForm(actions);
                  }}
                >
                  {({isSubmitting, status, setFieldValue}) => (
                    <Form ref={form}>
                      <Input
                        showErrorMsg={false}
                        type='text'
                        name='name'
                        labelClass='mb-[25px]'
                        className='pt-[20px] pr-[36px] pb-[20px] w-full bg-transparent border-b border-solid border-tprimary text-[16px]'
                        placeholder='Your name'
                      />
                      <Input
                        showErrorMsg={false}
                        type='email'
                        name='email'
                        labelClass='mb-[25px]'
                        className='pt-[20px] pr-[36px] pb-[20px] w-full bg-transparent border-b border-solid border-tprimary text-[16px]'
                        placeholder='Your email address'
                      />
                      <Input
                        showErrorMsg={false}
                        type='tel'
                        name='phone'
                        labelClass='mb-[25px]'
                        className='pt-[20px] pr-[36px] pb-[20px] w-full bg-transparent border-b border-solid border-tprimary text-[16px]'
                        placeholder='Mobile no'
                      />
                      <TextArea
                        className='py-[20px] pr-[36px] mb-[32px] w-full bg-transparent border-b border-solid border-tprimary text-[16px] resize-none'
                        name='comment'
                        rows='6'
                        placeholder='How can we help you?'
                      ></TextArea>
                      <Buttons
                        type='submit'
                        className={`tracking-[0.5px] btn-flat rounded-full mx-auto font-medium uppercase${
                          isSubmitting ? ' loading' : ''
                        }`}
                        themeColor={colors.tprimary}
                        size='md'
                        color='#fff'
                        title='Send Message'
                      />
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactForm;
